<template>
  <v-container class="container">
    <v-card
      class="mb-12 pa-4"
      height="100%"
      flat
    >
      <v-card-text>
        <p class="text-h5">Observaciones</p>
        <p>¿Hay algo que debamos saber sobre este tablero o subcircuitos? Quizás si corresponde a un tablero de relevancia dentro de tu operación,
          si opera 24/7 o únicamente en franjas horarias específicas, etc.
          Cualquier cosa que nos sirva para entender más sobre este punto, nos sirve!</p>
      </v-card-text>

      <v-form>
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-textarea
              v-model="form.observations"
              filled
              name="input-7-4"
              label="Comentarios"
              clearable
              prepend-icon="mdi-comment"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <Footer
      :currentStep="4"
      :loadingForward="loadingForward"
      :loadingBackward="loadingBackward"
      @nextStep="handleNextStep($event)"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Footer from './footer.vue'

export default {
  name: 'ww-observations',
  components: { Footer },
  data() {
    return {
      loadingForward: false,
      loadingBackward: false,
      form: {
        observations: null,
      }
    }
  },
  mounted() {
    if (this.boardId) {
      const wizard = this.docs.find((item) => item._id === this.$route.params.wizardId)
      const board = wizard.boards.find((item) => item._id === this.boardId)
      if (board && board.observations) this.form.observations = board.observations
    }
  },
  computed: {
    ...mapGetters({
      boardId: 'wizard/boardId',
      docs: 'wizard/docs',
    }),
  },
  methods: {
    ...mapActions({
      updateElectricBoard: 'wizard/UPDATE_BOARD_DATA',
    }),
    async handleNextStep(event) {
      const { step, direction } = event
        if (direction === 'forward') this.loadingForward = true
        if (direction === 'backward') this.loadingBackward = true
      try {
        await this.updateElectricBoard({
          form: { observations: this.form.observations },
          boardId: this.boardId,  
        })
      } catch {
        this.showAlert = true
      } finally {
        if (direction === 'forward') this.loadingForward = false
        if (direction === 'backward') this.loadingBackward = false
        this.$emit('nextStep', { nextStep: step, form: this.form.observations })
      }
    },
  },
}
</script>
<style scoped>
.container {
  max-width: 1080px;
}
</style>