<template>
  <v-row>
    <v-col cols="7" class="pb-8">
      <p>{{ porcentage }}% Completado ({{ currentStep }} de 5)</p>
      <v-progress-linear
        color="indigo"
        height="10"
        :value="porcentage"
        rounded
      ></v-progress-linear>
    </v-col>
    <v-col cols="5" fluid class="d-flex justify-end">
      <v-btn
        v-if="currentStep < 5"
        class="mx-2"
        fab
        dark
        small
        color="warning"
        elevation="0"
        :loading="loadingBackward"
        :disabled="currentStep === 1"
        @click="goToPreviousStep"
      >
        <v-icon>
          mdi-step-backward
        </v-icon>
      </v-btn>
      <v-btn
        v-if="currentStep < 5"
        class="mx-2"
        fab
        dark
        small
        color="warning"
        elevation="0"
        :loading="loadingForward"
        @click="goToNextStep"
      >
        <v-icon>
          mdi-step-forward
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ww-footer',
  props: {
    currentStep: Number,
    loadingForward: Boolean,
    loadingBackward: Boolean,
  },
  data() {
    return {
      message: 'Avance guardado automaticamente',
      timeout: 3000,
    }
  },
  computed: {
    porcentage() {
      return (this.currentStep * 100) / 5
    },
  },
  methods: {
    goToNextStep() {
      this.$emit('nextStep', { step: this.currentStep + 1, direction: 'forward' })
    },
    goToPreviousStep() {
      this.$emit('nextStep', { step: this.currentStep - 1, direction: 'backward' })
    },
  },
}
</script>
<style scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(66 66 66 / 55%) !important;
}
</style>