<template>
<v-container class="wizard-container pa-0" fluid>
  <v-snackbar
    v-model="snackbar"
    :timeout="3000"
    shaped
    color="primary"
    text
    top
  >
    {{ messageSnackbar }}
  </v-snackbar>
  <Header :isLarge="false"/>
  <v-stepper v-model="step" height="100%" class="stepper">
    <div class="wizard-header">
      <v-stepper-header class="stepper-header">
        <v-stepper-step :complete="step > 1" step="1">
          Tablero Eléctrico
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Fotografías Tablero
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3"  step="3">
          Sub Circuitos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 4" step="4">
          Comentarios
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="5">
          Resumen
        </v-stepper-step>
      </v-stepper-header>
    </div>

    <v-stepper-items>
      <v-stepper-content step="1">
        <ElectricBoard
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="2">
        <ElectricBoardPics
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="3">
        <SubCircuits
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="4">
        <Observations
          v-if="step !== 5"
          @nextStep="handleNextStep($event)"
        />
      </v-stepper-content>

      <v-stepper-content step="5">
        <Resume
          v-if="step === 5"
          :electricBoards="electricBoards"
          :wizardId="wizardId"
          :status="status"
          @addBoard="addBoard"
          @editBoard="editBoard($event)"
          @sendForm="handleStatusUpdated($event)"
        />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ElectricBoard from './partials/electricBoard.vue'
import Header from './partials/header.vue'
import ElectricBoardPics from './partials/electricBoardPics.vue'
import SubCircuits from './partials/subCircuits.vue'
import Observations from './partials/observations.vue'
import Resume from './partials/resume.vue'

export default {
  name: 'ww-wizard',
  components: {
    ElectricBoard,
    Header,
    ElectricBoardPics,
    SubCircuits,
    Observations,
    Resume,
  },
  data() {
    return {
      step: null,
      snackbar: false,
      messageSnackbar: 'Avance guardado automáticamente',
      form: {
        _id: null,
        show: false,
        electricBoard: {},
        electricBoardPics: [],
        subCircuits: [],
        observations: null,
      },
      electricBoards: [],
      clearForm: false,
      status: null,
    }
  },
  async mounted() {
    const { toResume, status } = this.$route.params
    const docFound = this.docs.find((item) => item._id === this.wizardId)
    if (docFound) {
      this.electricBoards = docFound.boards.map((item) => ({ ...item, show: false }))
    }
    
    this.status = status
    this.step = this.currentStep || 1
    if (toResume) await this.updateStep(5)
  },
  computed: {
    ...mapGetters({
      docs: 'wizard/docs',
      currentStep: 'wizard/currentStep',
      currentBoardId: 'wizard/boardId',
    }),
    wizardId() {
      return this.$route.params.wizardId
    },
  },
  methods: {
    ...mapActions({
      updateStep: 'wizard/UPDATE_STEP',
      updateBoardId: 'wizard/UPDATE_BOARD_ID',
      removeBoardStore: 'wizard/REMOVE_BOARD',
    }),
    async handleNextStep(event) {
      const { nextStep, form } = event
      switch (this.step) {
        case 1:
          this.form.electricBoard = form
          break
        case 2:
          this.form.electricBoardPics = form
          break
        case 3:
          this.form.subCircuits = form
          break
        case 4:
          this.form.observations = form
          this.electricBoards.push(this.form)
          break
        default:
          break
      }
      this.updateStep(nextStep)
      this.snackbar = true
    },
    addBoard() {
      this.form = {
        _id: null,
        show: false,
        electricBoard: {},
        electricBoardPics: [],
        subCircuits: [],
        observations: null,
      }
      this.updateBoardId(this.form._id)
      this.updateStep(1)
    },
    editBoard(data) {
      this.form = { ...data}
      this.updateStep(1)
      this.updateBoardId(this.form._id)
    },
    handleStatusUpdated(event) {
      this.status = event.status
      this.messageSnackbar = event.message
      this.snackbar = true
      this.$router.push({ name: 'home', params: { status: this.status } })
    }
  },
  watch: {
    currentStep(value) {
      this.step = value
    }
  },
}
</script>
<style scoped>
.wizard-container {
  height: 100%;
}
.stepper {
  background-color: #FAFAFA;
}
.stepper-header {
  background-color: #fff;
}
.wizard-header {
  padding: 20px;
}
.wizard-content {
  height: 100% !important;
}
</style>