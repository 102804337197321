import axios from 'axios'
import apiURL from '@/constants/api.js'

const INITIAL_STATE = () => ({
  docs: [],
  currentStep: 1,
  boardId: null,
})

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  actions: {
    UPDATE_STEP({ commit }, payload) {
      commit('UPDATE_CURRENT_STEP', payload)
    },
    UPDATE_BOARD_ID({ commit }, payload) {
      commit('UPDATE_CURRENT_BOARD_ID', payload)
    },
    async STORE_GENERAL_DATA({ commit, rootGetters }, payload) {
      try {
        const { form, userId, status } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }

        const { data: response } = await axios.post(`${apiURL}/wizard-forms`, { form, userId, status }, { headers })
        if (response.success) {
          commit('STORE_SUCCESS', response.data)
          return response.data._id
        } else {
          return null
        }
      } catch {
        return null
      }
    },
    async UPDATE_GENERAL_DATA({ commit, rootGetters }, payload) {
      try {
        const { form, wizardId, boardId } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }
       
        const url = `${apiURL}/wizard-forms/general-data/${wizardId}`
        const { data: response } = await axios.put(url, { form, boardId }, { headers })

        if (response.success) {
          commit('STORE_SUCCESS', response.data)
          return response.data._id
        } else {
          return null
        }
      } catch {
        return null
      }
    },
    async STORE_BOARD_DATA({ commit, dispatch, rootGetters }, payload) {
      try {
        const { form, wizardId } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }
       
        const url = `${apiURL}/wizard-forms/board-data/`
        const { data: response } = await axios.post(url, { form, wizardId }, { headers })

        if (response.success) {
          dispatch('UPDATE_GENERAL_DATA', { wizardId, boardId: response.data._id })
          commit('STORE_BOARD_SUCCESS', response.data)
          commit('UPDATE_CURRENT_BOARD_ID', response.data._id)
        }
      } catch {
        return null
      }
    },
    async UPDATE_BOARD_DATA({ commit, rootGetters }, payload) {
      try {
        const { form, boardId } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }
       
        const url = `${apiURL}/wizard-forms/board-data/${boardId}`
        const { data: response } = await axios.put(url, { form }, { headers })

        if (response.success) {
          commit('STORE_BOARD_SUCCESS', response.data)
          return response.data._id
        } else {
          return null
        }
      } catch {
        return null
      }
    },
    async REMOVE_BOARD({ commit, rootGetters }, payload) {
      try {
        const { boardId, wizardId } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }
       
        const url = `${apiURL}/wizard-forms/delete-board/${wizardId}/${boardId}`
        const { data: response } = await axios.delete(url, { headers })

        if (response.success) {
          commit('REMOVE_BOARD_SUCCESS', payload)
        }
      } catch {
        return null
      }
    },
    async FETCH_BOARDS_BY_USER({ commit, rootGetters, state }, payload) {
      const { userId } = payload
      Object.assign(state, INITIAL_STATE())

      const authorization = rootGetters['auth/headers']

      const headers = {
        'Content-Type': 'application/json',
        ...authorization,
      }
      const url = `${apiURL}/wizard-forms/by-user/${userId}`
      const { data: response } = await axios.get(url, { headers })
      commit('STORE_BY_USER_SUCCESS', response.data)
      return response.data
    },
    async SEND_FORM({ commit, rootGetters }, payload) {
      try {
        const { wizardId, status } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }

        const url = `${apiURL}/wizard-forms/update-status/${wizardId}`
        const { data: response } = await axios.put(url, { status }, { headers })
        if (response.success) {
          commit('UPDATE_WIZARD_STATUS', { wizardId, status })
          return response.success
        } else {
          return response.success
        }
      } catch {
        return null
      }
    },
    async DELETE_WIZARD({ commit, rootGetters, state }, payload) {
      try {
        const { wizardId } = payload
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }

        const fileNames = []
        const wizard = state.docs.find((item) => item._id === wizardId)

        wizard.boards.forEach((board) => {
          // Extract fileNames from electricBoardPics
          board.electricBoardPics.forEach((pic) => {
            if (pic.fileName) {
              fileNames.push(pic.fileName)
            }
          })
      
          // Extract fileNames from subCircuits
          board.subCircuits.forEach((subCircuit) => {
            subCircuit.photoList.forEach((photo) => {
              if (photo.fileName) {
                fileNames.push(photo.fileName)
              }
            })
          })
        })

        for (const fileName of fileNames) {
          await axios.post(`${apiURL}/wizard-forms/delete-picture`, { fileName }, { headers })
        }

        const url = `${apiURL}/wizard-forms/${wizardId}`
        const { data: response } = await axios.delete(url, { headers })

        if (response.success) {
          commit('DELETE_SUCCESS', payload)
          return wizardId
        } else {
          return null
        }
      } catch {
        return null
      }
    },
  },
  mutations: {
    UPDATE_CURRENT_STEP(state, payload) {
      state.currentStep = payload
    },
    UPDATE_CURRENT_BOARD_ID(state, payload) {
      state.boardId = payload
    },
    STORE_DOCS(state, payload) {
      state.docs = payload
    },
    STORE_SUCCESS(state, payload) {
      state.docs.push(payload)
    },
    REMOVE_BOARD_SUCCESS(state, payload) {
      const index = state.docs.map((item) => item._id).indexOf(payload.wizardId)

      if (index >= 0) {
        const boardIndex = state.docs[index].boards.map(item => item._id).indexOf(payload.boardId)
        if (boardIndex >= 0) {
          state.docs[index].boards = state.docs[index].boards.filter(item => item._id !== payload.boardId)
        }
      }
    },
    STORE_BY_USER_SUCCESS(state, payload) {
      state.docs = payload
    },
    STORE_BOARD_SUCCESS(state, payload) {
      const index = state.docs.map((item) => item._id).indexOf(payload.wizardId)

      if (index >= 0) {
        const boardIndex = state.docs[index].boards.map(item => item._id).indexOf(payload._id)
        if (boardIndex >= 0) {
          state.docs[index].boards[boardIndex] = payload
        } else {
          state.docs[index].boards.push(payload)
        }
      }
    },
    UPDATE_WIZARD_STATUS(state, payload) {
      const index = state.docs.map((item) => item._id).indexOf(payload.wizardId)
      if (index > 0) {
        state.docs[index].status = payload.status
      }
    },
    DELETE_SUCCESS(state, payload) {
      state.docs = state.docs.filter((item) => item._id !== payload.wizardId)
    },
  },
  getters: {
    docs: (state) => state.docs,
    currentStep: (state) => state.currentStep,
    boardId: (state) => state.boardId,
    generalData: (state) => state.generalData,
    boards: (state) => state.boards,
  },
}
