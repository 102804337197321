<template>
  <v-container class="container">
    <v-alert
      v-model="showAlert"
      border="top"
      :type="alertType"
      dismissible
    >
      {{ message }}
    </v-alert>
    <p class="text-h5">Resumen</p>
    <p>¡Felicitaciones! Completaste el levantamiento del tablero eléctrico</p>
    <p>Acá podrás ver todos los tableros que has levantado.</p>
    <p>¿Te falta subir la información de alguno? Selecciona “Nuevo Tablero”.
      Si ya has terminado, presiona “Enviar” y nosotros haremos el resto del trabajo.
      Si te faltan tableros pero necesitas continuar más adelante, puedes acceder a este espacio más tarde.
      Tu trabajo ya hecho está guardado y no tienes que repetirlo nuevamente.</p>
    
    <v-row justify="center" class="my-16">
      <v-col cols="12" md="4" v-for="(item, indexItem) of electricBoards" :key="indexItem">
        <v-card
          class="mx-auto"
          width="400"
          outlined
          shaped
        >
          <v-card-title class="card-title">
            <v-icon color="white">mdi-lightning-bolt-circle</v-icon>
            <div class="ml-4">{{ item.electricBoard?.name }}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-expand-transition>
              <div v-show="item.show">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{item.electricBoard?.ubication}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-folder-image</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{ getImagesContent(indexItem) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-subdirectory-arrow-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{item.subCircuits.length}} Sub Circuitos</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end" class="pb-4 pr-4">
              <v-btn
                text
                color="primary"
                @click="editItem(item)"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                text
                color="error"
                :loading="itemDeleting === item._id"
                @click="removeItem(item._id)"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn small color="warning" @click="$emit('addBoard')">
          Nuevo Tablero
          <v-icon small right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn small color="primary" class="float-end" @click="sendForm" :loading="loading">
          Enviar
          <v-icon small right>mdi-send</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Footer :currentStep="5"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Footer from './footer.vue'

export default {
  name: 'ww-resume',
  components: { Footer },
  data() {
    return {
      loading: false,
      itemDeleting: null,
      showAlert: false,
      message: null,
      alertType: 'success',
    }
  },
  computed: {
    ...mapGetters({
      docs: 'wizard/docs',
    }),
    wizard() {
      return this.docs.find((item) => item._id === this.$route.params.wizardId)
    },
    electricBoards() {
      return this.wizard.boards.map((item) => ({ ...item, show: true }))
    },
    status() {
      return this.wizard.status
    }
  },
  methods: {
    ...mapActions({
      sendFormStatus: 'wizard/SEND_FORM',
      removeBoardStore: 'wizard/REMOVE_BOARD'
    }),
    toggleShow(index) {
      this.electricBoards[index].show = !this.electricBoards[index].show
    },
    getImagesContent(index) {
      const images = this.electricBoards[index].electricBoardPics.filter((item) => item.url)
      return `${images.length} de ${this.electricBoards[index].electricBoardPics.length} imagenes`
    },
    async removeItem(boardId) {
      this.itemDeleting = boardId
      await this.removeBoardStore({ wizardId: this.$route.params.wizardId, boardId })
      this.itemDeleting = null
    },
    editItem(data) {
      this.$emit('editBoard', data)
    },
    async sendForm() {
      try {
        this.loading = true
        const sended = await this.sendFormStatus({ wizardId: this.$route.params.wizardId, status: 'Enviado' })
        if (sended) {
          this.$emit('sendForm', { status: 'Enviado', message: 'Levantamiento enviado exitosamente' })
        } else {
          this.message = 'Ocurrió un error al realizar el envio'
          this.alertType = 'error'
          this.showAlert = true
        }
      } catch (error) {
        this.message = 'Ocurrió un error al realizar el envio'
        this.alertType = 'error'
        this.showAlert = true
      }
      this.loading = false
    }
  },
}
</script>
<style scoped>
.container {
  max-width: 1080px;
}
.card-title {
  background-color: #0D47A1;
  color: #fff;
}
</style>