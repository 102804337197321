// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import wizard from './modules/wizard'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    wizard,
  },
  plugins: [
    createPersistedState({
      paths: [
        'app',
        'auth',
        'wizard',
      ],
    }),
  ],
})

export default store
