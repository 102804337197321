<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      shaped
      color="primary"
      text
      top
    >
      {{ messageSnackbar }}
    </v-snackbar>
    <Header :isLarge="false"/>
    <v-container class="container">
      <v-row  cols="12">
        <v-col>
          <p class="text-h5">{{ greetings }}, {{ userName}}</p>
        </v-col>
      </v-row>
      <v-row  cols="12">
        <v-col>
          <p>Aquí podrás encontrar los levantamientos que tienes en curso, así como editarlos o eliminarlos. También podrás crear uno nuevo si lo deseas.</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="10">
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line, actions"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="headers"
            :items="data"
            disable-filtering
            disable-sort
            disable-pagination
            hide-default-footer
            no-data-text="Sin datos para mostrar"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                color="primary"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="warning" class="float-end" @click="startWizard">
            Nuevo levantamiento
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-card-title color="primary">
          Atención
        </v-card-title>

        <v-card-text>
          Estas a punto de eliminar datos que no podrás recuperar.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="requestDelete"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import Header from '@/components/partials/header.vue'

export default {
  name: 'ww-home',
  components: { Header },
  props: { userId: String },
  data() {
    return {
      wizard: null,
      loading: true,
      headers: [
        { text: 'Estado', value: 'status' },
        { text: 'Compañía', value: 'company' },
        { text: 'Fecha de creación', value: 'createdAt' },
        { text: 'Fecha úlmima modificación', value: 'updatedAt' },
        { text: '', value: 'actions', sortable: false }
      ],
      data: [],
      options: {
        sortDesc: false,
        groupDesc: false,
        multiSort: false,
        mustSort: false
      },
      dialog: false,
      selectedItem: null,
      snackbar: false,
      messageSnackbar: 'Levantamiento enviado'
    }
  },
  beforeMount() {
    if (!this.authenticated) {
      this.$router.push({ name: 'login' })
    }
  },
  mounted() {
    this.findWizard()
    if (this.$route.params.status && this.$route.params.status === 'Enviado') {
      this.snackbar = true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated',
    }),
    greetings() {
      return new Date().getHours() < 12 ? 'Buenos días' : 'Buenas tardes'
    },
    userName() {
      return this.authenticated ? this.user.profile.firstName : null
    },
  },
  methods: {
    ...mapActions({
      fetchFormsByUser: 'wizard/FETCH_BOARDS_BY_USER',
      deleteForm: 'wizard/DELETE_WIZARD'
    }),
    async findWizard() {
      const response = await this.fetchFormsByUser({ userId: this.user.id })
      if (response) {
        this.data = response.map((item) => {
          const { _id, generalData, status, createdAt, updatedAt } = item
          return {
            _id,
            status,
            company: generalData.company,
            createdAt: dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss'),
            updatedAt: dayjs(updatedAt).format('DD/MM/YYYY HH:mm:ss'),
          }
        })
      }
      this.loading = false
    },
    startWizard() {
      this.$router.push({ name: 'welcome' })
    },
    editItem(item) {
      this.$router.push({ name: 'wizard', params: { wizardId: item._id, toResume: true, status: item.status } })
    },
    deleteItem(item) {
      this.dialog = true
      this.selectedItem = item._id
    },
    async requestDelete() {
      this.dialog = false
      const idDeleted = await this.deleteForm({ wizardId: this.selectedItem })
      this.data = this.data.filter((item) => item._id !== idDeleted)
      this.selectedItem = null
    },
    cancelDelete() {
      this.dialog = false
      this.selectedItem = null
    },
  },
}
</script>
<style scoped>
.container {
  padding: 30px;
}
</style>