<template>
  <v-container class="main-container" fluid>
    <v-container class="inner-container">
      <Header :isLarge="true"/>
      <v-row justify="center">
        <h1>Confirmación de email</h1>
      </v-row>
      <v-row justify="center">
        <v-icon size="100" class="my-16" color="white">{{ icon }}</v-icon>
      </v-row>
      <v-row justify="center">
        <p v-if="message">{{ message }}</p>
        <p v-else>Procesando tu confirmación...</p>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios'
import Header from '@/components/partials/header.vue'
import apiURL from '@/constants/api.js'

export default {
  name: 'ww-confirmation',
  components: { Header },
  data() {
    return {
      message: '',
      icon: null,
    }
  },
  created() {
    this.confirmEmail()
  },
  methods: {
    async confirmEmail() {
      try {
        this.icon = 'mdi-email-sync'
        const token = this.$route.query.token
        const response = await axios.post(`${apiURL}/auth/confirm-email`, { token })

        if (response.data.success === true) {
          this.message = response.data.message
          this.icon = 'mdi-email-check'
        }
      } catch (error) {
        this.message = 'Hubo un error al confirmar su correo electrónico. Por favor, inténtelo de nuevo más tarde.'
        this.icon = 'mdi-email-alert'
      }
    }
  }
}
</script>

<style scoped>
  .main-container {
    background-image: url('@/assets/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fafafa;
    height: 100%;
    width: 100%;
  }
  .main-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust color and transparency here */
    z-index: 1;
  }
  .inner-container {
    background-color: #014171;
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 100px;
  }
</style>
