// modules/auth.js
import axios from 'axios'
import apiURL from '@/constants/api.js'

const INITIAL_STATE = () => ({
  headers: null,
  user: null,
})

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  actions: {
    async LOGIN({ commit }, payload) {
      try {
        const { credentials } = payload
        const { data: authResponse } = await axios.post(`${apiURL}/auth/login`, credentials, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (authResponse.success) {
          const tokenData = authResponse.data
          const headers = { Authorization: `${tokenData.token_type} ${tokenData.access_token}` }
          const { data: userResponse } = await axios.get(`${apiURL}/users/me`, { headers })
          commit('FETCH_AUTHENTICATED_USER_SUCCESS', userResponse.data)

          if (!userResponse.data.enabled || !userResponse.data.verified) {
            return {
              ok: false,
              message: 'Te hemos enviado un correo de confirmación, debes de confirmarlo antes de iniciar sesión.',
              sendMail: true,
            }
          } else {
            commit('LOGIN_SUCCESS', { headers })
            return { ok: true }
          }
        } else {
          commit('LOGIN_FAILED', authResponse.message)
          return { ok: false, message: 'Correo y/o contraseña no coinciden con nuestros registros' }
        }
      } catch (err) {
        commit('LOGIN_FAILED', err.message)
        return { ok: false, message: err.response.data.message}
      }
    },
    LOGOUT({ commit }) {
      commit('LOGOUT')
    },
    async REGISTER({ commit }, payload) {
      try {
        commit('LOGIN_REQUESTED', payload)
        const {
          email,
          firstName,
          lastName,
          password,
        } = payload

        const user = {
          email,
          firstName,
          lastName,
          password,
          fromWizard: true,
        } 

        const { data: authResponse } = await axios.post(`${apiURL}/auth`, user, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        return !!(authResponse.success)
      } catch (error) {
        return error.message
      }
    },
    async SEND_EMAIL_CONFIRMATION({ state }) {
      try {
        const url = `${apiURL}/auth/send-email-confirmation`
        const body = {
          email: state.user.email,
          userId: state.user.id,
          firstName: state.user.profile.firstName,
          lastName: state.user.profile.lastName,
        }
        const { data: authResponse } = await axios.post(url, body, {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        return authResponse.emailSended
      } catch (error) {
        return error.message
      }
    }
  },
  mutations: {
    LOGIN_SUCCESS(state, payload) {
      state.headers = payload.headers
    },
    FETCH_AUTHENTICATED_USER_SUCCESS(state, payload) {
      state.user = Object.assign(payload, { loadedAt: Date.now() })
    },
    LOGOUT(state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  getters: {
    authenticated: (state) => !!state.headers,
    user: (state) => state.user,
    headers: (state) => state.headers,
  },
}
